/* 
---------------------------------------------
Services Style
--------------------------------------------- 
*/

.services {
  padding-top: 130px;
  position: relative;
  z-index: 999 !important;
}

.services .section-heading p {
  margin-top: 10px;
  margin-left: 18%;
  margin-right: 18%;
}

.services .container-fluid {
  padding-left: 65px;
  padding-right: 65px;
}

.services:after {
  content: "";
  background-image: url(../../images/services-left-dec.png);
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  bottom: -300px;
  width: 261px;
  height: 368px;
  z-index: 0;
}

.services:before {
  content: "";
  background-image: url(../../images/services-right-dec.png);
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  top: 0;
  width: 1136px;
  height: 244px;
  z-index: -1;
}

.services .section-heading {
  text-align: center;
  margin-bottom: 80px;
}

.services .section-heading .line-dec {
  margin: 0 auto;
}

.service-item {
  position: relative;
  z-index: -1;
  padding: 30px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 10px;
  border-top-right-radius: 50px;
  transition: all 0.3s;
}

.service-item .icon {
  margin-left: 0px;
  margin-bottom: 30px;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  transition: all 0.3s;
}

.service-item h4 {
  transition: all 0.3s;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 15px;
}

.service-item p {
  transition: all 0.3s;
  font-size: 15px;
  margin-bottom: 20px;
}

.service-item:hover h4,
.service-item:hover p,
.service-item:hover .text-button a {
  color: #fff;
}

.first-service .icon {
  background-image: url(../../images/service-icon-01.png);
}

.first-service:hover .icon {
  background-image: url(../../images/service-icon-hover-01.png);
}

.second-service .icon {
  background-image: url(../../images/service-icon-02.png);
}

.second-service:hover .icon {
  background-image: url(../../images/service-icon-hover-02.png);
}

.third-service .icon {
  background-image: url(../../images/service-icon-03.png);
}

.third-service:hover .icon {
  background-image: url(../../images/service-icon-hover-03.png);
}

.fourth-service .icon {
  background-image: url(../../images/service-icon-04.png);
}

.fourth-service:hover .icon {
  background-image: url(../../images/service-icon-hover-04.png);
}

.service-item:hover {
  background-image: url(../../images/service-bg.jpg);
  background-position: right top;
  background-repeat: no-repeat;
  background-size: cover;
}
.section-heading {
  position: relative;
  z-index: 2;
}

.section-heading h4 {
  color: #2a2a2a;
  font-size: 30px;
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.section-heading h4 em {
  font-style: normal;
  color: #4b8ef1;
}

.section-heading img {
  width: 45px;
  height: 2px;
}

.section-heading p {
  margin-top: 10px;
}

.show-up {
  position: relative;
  z-index: 2;
}

.white-button a {
  display: inline-block !important;
  padding: 10px 20px !important;
  color: #4b8ef1 !important;
  text-transform: capitalize;
  font-size: 15px;
  background-color: #fff;
  border-radius: 23px;
  font-weight: 500 !important;
  letter-spacing: 0.3px !important;
  transition: all 0.5s;
}

.white-button a i {
  margin-left: 5px;
  font-size: 18px;
}

.white-button a:hover {
  background-color: #4b8ef1;
  color: #fff !important;
}

.text-button a {
  font-size: 15px;
}

.text-button a i {
  margin-left: 5px;
  font-size: 14px;
  transition: all 0.3s;
}

.text-button a:hover i {
  margin-left: 8px;
}

.gradient-button a {
  display: inline-block !important;
  padding: 10px 20px !important;
  color: #fff !important;
  text-transform: capitalize;
  font-size: 15px;
  background: rgb(19, 175, 240);
  background: linear-gradient(
    105deg,
    rgba(91, 104, 235, 1) 0%,
    rgba(40, 225, 253, 1) 100%
  );
  border-radius: 23px;
  font-weight: 500 !important;
  letter-spacing: 0.3px !important;
  transition: all 0.5s;
}

.gradient-button a:hover {
  color: #fff !important;
  background: rgb(19, 175, 240);
  background: linear-gradient(
    105deg,
    rgba(91, 104, 235, 1) 0%,
    rgba(40, 225, 253, 1) 100%
  );
}

.border-button a {
  display: inline-block !important;
  padding: 10px 20px !important;
  color: #4b8ef1 !important;
  border: 1px solid #4b8ef1;
  text-transform: capitalize;
  font-size: 15px;
  display: inline-block;
  background-color: #fff;
  border-radius: 23px;
  font-weight: 500 !important;
  letter-spacing: 0.3px !important;
  transition: all 0.5s;
}
/* #fdfefe
#4d95f1
#39bcf7
#537aed
#9fd7fa */
.border-button a:hover {
  background-color: #4b8ef1;
  color: #fff !important;
}
