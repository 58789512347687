.App {
  text-align: center;
}

.anta-regular {
  font-family: "Fredoka", sans-serif;
  font-style: normal;
  color: rgb(248, 232, 227) !important;
  font-size: 5vh !important ;
  letter-spacing: 0.5vh;
}
.anta-regular-main {
  font-family: "Fredoka", sans-serif;
  font-style: normal;
  color: rgb(248, 232, 227) !important;
  font-size: 5vh !important ;
  letter-spacing: 0.5vh;
  text-shadow: 0 0 5px rgba(0, 0, 0, 1);
}
.eastechy-main-hero-img-2 {
  width: 150% !important;
  height: auto !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.nav-item {
  margin: 2px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.about_section {
  background-repeat: no-repeat;
  background-size: 100%;
}
.img_leadgeneration {
  width: 65% !important;
}
.img-aboutsus {
  width: 100% !important;
  margin-left: 30px;
}
@media only screen and (max-width: 991px) {
  /* CSS rule to hide the image */
  .img-aboutsus {
    display: none;
  }
  .img_leadgeneration {
    width: 50% !important;
  }
  .about_section {
    margin-top: -50px;
  }
  .service_section {
    margin-top: -30px !important;
  }
}
.eastechy-logo {
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.4) !important;
}
