.class {
  padding: 140px 0px 60px 0px;
}

.class .titlepage p {
  font-size: 17px;
  padding-top: 15px;
  color: #fff;
}
.class .titlepage h2 {
  color: #fff !important;
}

.class_box {
  border-radius: 10px;
  background: #8d9743;
  margin-bottom: 58px;
  transition: ease-in all 0.5s;
  padding: 20px 20px 35px 20px;
}

.class_box h3 {
  color: #fff;
  font-size: 25px;
  transition: ease-in all 0.5s;
  font-weight: bold;
  line-height: 22px;
  padding: 30px 0;
}

.class_box p {
  color: #fff;
  font-size: 16px;
  line-height: 28px;
  transition: ease-in all 0.5s;
}

.blue {
  background: #473a6e;
}

.margi_bottom {
  margin-bottom: 30px;
}
/* Header and navbar */
.hero-section-navbar-sectionpage {
  background-color: #537aed;
  border-radius: 0 0 350px 0;
}
.header_section_Services {
  padding: 15px 0;
}

.header_section_Services.container-fluid {
  padding-right: 25px;
  padding-left: 25px;
}

.navbar-brand span {
  font-weight: bold;
  color: #ffffff;
  font-size: 24px;
}

.custom_nav-container {
  padding: 0;
}

.custom_nav-container .navbar-nav {
  margin: auto;
}

.custom_nav-container .navbar-nav .nav-item .nav-link {
  padding: 5px 25px;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  border-radius: 5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.custom_nav-container .navbar-nav .nav-item:hover .nav-link,
.custom_nav-container .navbar-nav .nav-item.active .nav-link {
  color: #000000;
  background-color: #ffffff;
}

.custom_nav-container .navbar-toggler {
  outline: none;
}

.custom_nav-container .navbar-toggler {
  padding: 0;
  width: 37px;
  height: 42px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.custom_nav-container .navbar-toggler span {
  display: block;
  width: 35px;
  height: 4px;
  background-color: #ffffff;
  margin: 7px 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
  border-radius: 5px;
  transition: all 0.3s;
}

.custom_nav-container .navbar-toggler span::before,
.custom_nav-container .navbar-toggler span::after {
  content: "";
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  top: -10px;
  border-radius: 5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.custom_nav-container .navbar-toggler span::after {
  top: 10px;
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] span {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] span::before,
.custom_nav-container .navbar-toggler[aria-expanded="true"] span::after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  top: 0;
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] .s-1 {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  margin: 0;
  margin-bottom: -4px;
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] .s-2 {
  display: none;
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] .s-3 {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  margin: 0;
  margin-top: -4px;
}

.custom_nav-container .navbar-toggler[aria-expanded="false"] .s-1,
.custom_nav-container .navbar-toggler[aria-expanded="false"] .s-2,
.custom_nav-container .navbar-toggler[aria-expanded="false"] .s-3 {
  -webkit-transform: none;
  transform: none;
}

.quote_btn-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 2px;
}

.quote_btn-container a {
  color: #000000;
  text-transform: uppercase;
}

.quote_btn-container a span {
  margin-left: 5px;
}

.quote_btn-container a:hover {
  color: black;
}

.quote_btn-container .quote_btn {
  display: inline-block;
  padding: 5px 25px;
  color: #ffffff;
  border-radius: 5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: 1px solid #f8842b;
}

.quote_btn-container .quote_btn:hover {
  color: #ffffff;
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}
@media only screen and (max-width: 991px) {
  .services-main {
    margin-top: -100px;
  }
  .class_box {
    margin-bottom: -10px;
  }
  .quote_btn-container {
    align-items: center;
    margin-left: 30%;
  }
  .CALL-NOW {
    margin-left: 33%;
    margin-top: 2px;
  }
  .Links_name {
    margin-left: -35px !important;
  }
  .eastechy-logo {
    width: 60px;
  }
  .anta-regular-main {
    font-size: 3.5vh !important;
    margin-left: -15px;
  }
}
